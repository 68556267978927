<template>
  <svg :class="classes" :style="styles">
    <use :href="icon"></use>
  </svg>
</template>

<script>
export default {
  name: 'Icon',
  props: {
    type: {
      name: String,
      required: true
    },
    size: {
      type: [Number, String],
      default: 20
    }
  }, 
  computed: {
    classes () {
      return ['icon', 'icon-' + this.type]
    },
    styles () {
      if (!this.size) {
        return {}
      }

      return { width: this.size + 'px', height: this.size + 'px' }
    },
    icon () {
      return require('@/assets/img/icons.svg') + '#' + this.type
    }
  }
}
</script>
