import { getUsers } from '@/api/users'

function defaultState () {
  return {
    search: null,
    users: [],
    loading: false
  }
}

const state = defaultState()

const mutations = {
  SET_USERS (state, users) {
    state.users = users
  },
  SET_LOADING (state, status) {
    state.loading = status
  },
  SET_SEARCH (state, search) {
    state.search = search
  },
  RESET_STORE (state) {
    Object.assign(state, defaultState())
  }
}

const actions = {
  async getUsers ({ commit, state: { search } }) {
    commit('SET_LOADING', true)
    
    try {
      const { data: users } = await getUsers(search)

      commit('SET_USERS', users)
    } catch (e) {
      commit('SET_USERS', [])
    }

    commit('SET_LOADING', false)
  },
  setSearch ({ commit }, search) {
    commit('SET_SEARCH', search)
  },
  resetStore ({ commit }) {
    commit('RESET_STORE')
  }
}

export default {
  state,
  actions,
  mutations,
  namespaced: true
}