import Vue from 'vue'
import VueRouter from 'vue-router'
import { getItem } from '@/helpers/persistanceStorage.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/Home')
  },
  {
    path: '/notifications',
    name: 'notifications',
    meta: { auth: true },
    component: () => import('@/views/Notifications')
  },
  {
    path: '/',
    name: 'competitions',
    meta: { auth: true },
    component: () => import('@/views/Competitions')
  },
  {
    path: '/chat',
    name: 'chat',
    meta: { auth: true },
    component: () => import('@/views/Chat')
  },
  {
    path: '/chat/new-message',
    name: 'new-message',
    meta: { auth: true },
    component: () => import('@/views/NewMessage')
  },
  {
    path: '/chat/:userID',
    name: 'personal-chat',
    meta: { auth: true },
    props: route => ({ userID: route.params.userID }),
    component: () => import('@/views/PersonalChat')
  },
  {
    path: '/account/:userID?',
    name: 'account',
    props: route => ({ userID: route.params.userID }),
    component: () => import('../views/UserInfo/UserInfo')
  },
  {
    path: '/ratings',
    name: 'ratings',
    meta: { auth: true },
    component: () => import('@/views/Ratings')
  },
  {
    path: '/search',
    name: 'search',
    meta: { auth: true },
    component: () => import('@/views/Search')
  },
  {
    path: '/details/:id',
    name: 'details',
    props: route => ({
      competitionId: route.params.id,
    }),
    component: () => import('@/views/Details')
  },
  {
    path: '/details-event/:id',
    name: 'details-event',
    props: route => ({
      // competitionId: route.params.id,
      detailEventID: route.params.id
    }),
    // component: () => import('@/views/Details')
    component: () => import('@/views/Details-tournament-event')
  },
  {
    path: '/details-tournament/:id',
    name: 'details-tournament',
    props: route => ({
      tournamentId: route.params.id
    }),
    component: () => import('@/views/Details-tournament')
  },
  {
    path: '/details-tournament-event/:id',
    name: 'details-tournament-event',
    props: route => ({
      eventId: route.params.id
    }),
    component: () => import('@/views/Details-tournament-event')
  },
  {
    path: '/streamed-tournament/:id',
    name: 'streamed-tournament',
    props: route => ({
      competitionId: route.params.id
    }),
    component: () => import('@/views/SteramedTournament')
  },
  {
    path: '/streamed-tournament-event/:id',
    name: 'streamed-tournament-event',
    props: route => ({
      competitionId: route.params.id
    }),
    component: () => import('@/views/StreamedEvent')
  },
  {
    path: '/streamed-event/:id',
    name: 'streamed-event',
    props: route => ({
      competitionId: route.params.id
    }),
    component: () => import('@/views/StreamedEvent')
  },
  {
    path: '/verdict/:id',
    name: 'verdict',
    props: route => ({
      competitionId: route.params.id
    }),
    component: () => import('@/views/Verdict')
  },
  {
    path: '/newcompetition',
    name: 'newcompetition',
    meta: { auth: true },
    redirect: { name: 'newcompetition.discepline' },
    component: () => import('@/views/NewCompetition'),
    children: [
      {
        path: 'discepline',
        name: 'newcompetition.discepline',
        component: () => import('@/views/NewCompetition/Discepline')
      },
      {
        path: 'date',
        name: 'newcompetition.date',
        component: () => import('@/views/NewCompetition/Date')
      },
      {
        path: 'details',
        name: 'newcompetition.details',
        component: () => import('@/views/NewCompetition/Details')
      },
      {
        path: 'appearance',
        name: 'newcompetition.appearance',
        component: () => import('@/views/NewCompetition/Appearance'),
        redirect: { name: 'newcompetition.appearance.default' },
        children: [
          {
            path: '',
            name: 'newcompetition.appearance.default',
            component: () => import('@/views/NewCompetition/Appearance/Default')
          },
          {
            path: 'venue',
            name: 'newcompetition.appearance.venue',
            component: () => import('@/views/NewCompetition/Appearance/Venues')
          },
          {
            path: 'addons',
            name: 'newcompetition.appearance.addons',
            component: () => import('@/views/NewCompetition/Appearance/Addons')
          },
          {
            path: 'sponsors',
            name: 'newcompetition.appearance.sponsors',
            component: () => import('@/views/NewCompetition/Appearance/Sponsors')
          },
          {
            path: 'judgements',
            name: 'newcompetition.appearance.judgements',
            component: () => import('@/views/NewCompetition/Appearance/Judgements')
          },
          {
            path: 'description',
            name: 'newcompetition.appearance.description',
            component: () => import('@/views/NewCompetition/Appearance/Description')
          },
          {
            path: 'gender',
            name: 'newcompetition.appearance.gender',
            component: () => import('@/views/NewCompetition/Appearance/Gender')
          }
        ]
      },
      {
        path: 'competitors',
        name: 'newcompetition.competitors',
        component: () => import('@/views/NewCompetition/Competitors')
      },
      {
        path: 'competitors-external-link',
        name: 'newcompetition.competitors-external-link',
        component: () => import('@/views/NewCompetition/ExternalLink')
      },
      {
        path: 'invites',
        name: 'newcompetition.invites',
        component: () => import('@/views/NewCompetition/Invites')
      },
      {
        path: 'preview',
        name: 'newcompetition.preview',
        component: () => import('@/views/NewCompetition/Preview')
      },
      {
        path: 'final',
        name: 'newcompetition.final',
        component: () => import('@/views/NewCompetition/Final')
      }
    ]
  },
  {
    path: '/message',
    name: 'message',
    meta: { auth: false },
    props: route => ({
      message: route.params.message,
      color: route.params.color,
    }),
    component: () => import('../views/Message.vue'),
  },
  {
    path: '/settings',
    name: 'settings',
    meta: { auth: true },
    component: () => import('../views/Settings/Settings.vue'),
  },
  {
    path: '/settings/security',
    name: 'security',
    meta: { auth: true },
    component: () => import('../views/Settings/Security.vue'),
  },
  {
    path: '/settings/info',
    name: 'settings-info',
    meta: { auth: true },
    component: () => import('../views/Settings/Info.vue'),
  },
  {
    path: '/settings/terms',
    name: 'settings-terms',
    meta: { auth: true },
    component: () => import('../views/Settings/Terms.vue'),
  },
  {
    path: '/settings/policy',
    name: 'settings-policy',
    meta: { auth: true },
    component: () => import('../views/Settings/Policy.vue'),
  },
  {
    path: '/settings/feedback',
    name: 'settings-feedback',
    meta: { auth: true },
    component: () => import('../views/Settings/Feedback.vue'),
  },
  {
    path: '/settings/security/reset-password',
    name: 'settings-reset-password',
    meta: { auth: true },
    component: () => import('../views/Settings/ResetPassword.vue'),
  },
  {
    path: '/personal-info',
    name: 'personalInfo',
    meta: { auth: true },
    component: () => import('../views/Settings/PersonalInfo.vue'),
  },
  {
    path: '/physiological-data',
    name: 'physiologicalData',
    meta: { auth: true },
    component: () => import('../views/Settings/PhysiologicalData.vue'),
  },
  {
    path: '/authorization',
    name: 'authorization',
    component: () => import('../views/Authorization.vue'),
  },
  {
    path: '/registration',
    name: 'registration',
    component: () => import('../views/Registration.vue'),
  },
  {
    path: '/record/:id',
    name: 'record',
    component: () => import('@/views/Record'),
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('../views/ForgotPassword.vue'),
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    props: route => ({ token: route.query.token }),
    component: () => import('../views/RestorePass.vue'),
  },
  {
    path: '/login/google/:search',
    name: 'google-callback',
    props: route => ({ search: route.query }),
    component: () => import('../views/GoogleRedirect.vue'),
  },
  {
    path: '/login/mail/:search',
    name: 'mail-callback',
    props: route => ({ search: route.query }),
    component: () => import('../views/MailRedirect.vue'),
  },
  {
    path: '/chat-user-invite/:opponentId',
    name: 'chat-user-invite',
    meta: { auth: true },
    component: () => import('../views/ChatUserInvite'),
  },
  {
    path: '/details-opponent',
    name: 'details-opponent',
    meta: { auth: true },
    props: route => ({ oponentData: route.params.oponentData }),
    component: () => import('../views/DetailsOpponent'),
  },
  {
    path: '/coins-storage',
    name: 'coins-storage',
    meta: { auth: true },
    component: () => import('../views/CoinsStorage'),
  },
  {
    path: '/invitation/:hash',
    name: 'invitation',
    component: () => import('@/views/Invitation')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  const requireAuth = to.matched.some(item => item.meta.auth)

  if(requireAuth && !getItem('accessToken')) {
    next(to.path==="/" ? {name: 'home'}: {name: 'authorization'})
  } else {
    next()
  }
})

export default router
