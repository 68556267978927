import axios from '@/plugins/axios'

export function getNotifications() {
  return axios.get('/profile/notifications')
}

export function read(id) {
  return axios.post('/profile/notifications/read/' + id)
}

export function getNotificationsTexts() {
  return axios.get('/notification-texts')
}
