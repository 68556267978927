import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { ResizeObserver } from 'vue-resize'
import { CoolSelectPlugin } from 'vue-cool-select'
import i18n from '@/plugins/i18n'
import Antd from 'ant-design-vue'
import SvgIcon from './components/SvgIcon'
import VueYoutube from 'vue-youtube'
import VueMeta from 'vue-meta'
import RouterLinkBack from '@/components/RouterButtonBack/RouterLinkBack'
import swipeUpdate from './plugins/swipe-update'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

Vue.use(VueAwesomeSwiper)
import HeaderCompetitions from './components/HeaderCompetitions'
import FooterCompetitions from './components/FooterCompetitions'
import CustomHeader from './components/CustomHeader'
import SmoothPicker from 'vue-smooth-picker'

import ViewContainer from '@/components/View/ViewContainer'
import ViewHeader from '@/components/View/ViewHeader'
import ViewContent from '@/components/View/ViewContent'
import ViewFooter from '@/components/View/ViewFooter'
import Subview from '@/components/View/Subview'

import '@/plugins/moment'

import 'ant-design-vue/dist/antd.css'
import 'vue-cool-select/dist/themes/bootstrap.css'
import 'bootstrap/dist/css/bootstrap-reboot.css'
import "bootstrap/dist/css/bootstrap-grid.min.css"
import "@/assets/css/main.styl"
import "@/assets/css/date-picker.styl"
import "@/assets/css/settings.styl"
import "@/assets/css/index.styl"
import "@/assets/css/auth.styl"
import "@/assets/css/cheaps.styl"
import "@/assets/css/custom-divider.styl"
import goBack from '@/plugins/goBack'

Vue.use(Antd)
Vue.use(VueYoutube)
// Vue.use(BootstrapVue)
Vue.use(SmoothPicker)
Vue.use(CoolSelectPlugin)
Vue.use(VueMeta)
Vue.use(goBack)
Vue.use(swipeUpdate)

Vue.component('ViewContainer', ViewContainer)
Vue.component('ViewHeader', ViewHeader)
Vue.component('ViewContent', ViewContent)
Vue.component('ViewFooter', ViewFooter)
Vue.component('Subview', Subview)
Vue.component('router-link-back', RouterLinkBack)

Vue.component('HeaderCompetitions', HeaderCompetitions)
Vue.component('FooterCompetitions', FooterCompetitions)
Vue.component('SvgIcon', SvgIcon)
Vue.component('CustomHeader', CustomHeader)

Vue.component('resize-observer', ResizeObserver)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
