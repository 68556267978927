<template>
  <div class="subview" :class="classes">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Subview',
  props: {
    name: {
      type: String,
      default: null
    }
  },
  computed: {
    classes () {
      return (this.name || this.$route.name) + '-subview'
    }
  }
}
</script>