import moment from 'moment-timezone'
import authApi from '@/api/auth'
import { setItem, clear } from '@/helpers/persistanceStorage'

const state = {
  isSubmitting: false,
  isLoading: false,
  user: null,
  errors: {},
  message: null
}

const mutations = {
  setErrors(state, payload) {
    state.errors = payload
  },
  setUser(state, payload) {
    state.user = payload
  },
  setMessage(state, payload) {
    state.message = payload
  },
  setMetric(state, payload) {
    state.user.metric = payload
  },
  setGender(state, payload) {
    state.user.gender = payload
  }
}

const actions = {
  register({ commit }, credentials) {
    return new Promise(resolve => {
      authApi
        .register(credentials)
        .then(response => {
          // setItem('accessToken', response.data.token)
          resolve(response.data)
        })
        .catch(result => {
          commit('setMessage', result.response.data.message)
          commit('setErrors', result.response.data.errors)
        })
    })
  },
  login({ commit }, credentials) {
    clear()
    return authApi
      .login(credentials)
      .then(response => {
        setItem('accessToken', response.data.token)
        return response.data
      })
      .catch(result => {
        commit('setMessage', result.response.data.message)
        commit('setErrors', result.response.data.errors)
        return Promise.reject(result)
      })
  },
  logout({ commit, dispatch }, credentials) {
    return new Promise(resolve => {
      authApi
        .logout(credentials)
        .then(response => {
          setItem('accessToken', '')
          commit('setUser', null)
          clear()
          dispatch('home/stopIntervalCheckVerdicts')
          dispatch('competitions/stopIntervalCheckInvites')

          resolve(response.data)
        })
        .catch(result => {
          commit('setMessage', result.response.data.message)
          commit('setErrors', result.response.data.errors)
        })
    })
  },
  loginByGoogleCreds({ commit }, credentials) {
    return new Promise(resolve => {
      authApi
        .loginByGoogleCreds(credentials)
        .then(response => {
          setItem('accessToken', response.data.token)
          resolve(response.data)
        })
        .catch(result => {
          commit('setMessage', result.response.data.message)
          commit('setErrors', result.response.data.errors)
        })
    })
  },
  confirmRegistrationByMail({ commit }, credentials) {
    return new Promise(resolve => {
      authApi
        .confirmRegistrationByMail(credentials)
        .then(response => {
          // setItem('accessToken', response.data.token)
          resolve(response.data)
        })
        .catch(result => {
          commit('setMessage', result.response.data.message)
          commit('setErrors', result.response.data.errors)
        })
    })
  },
  getUser({ commit }, code) {
    return new Promise(resolve => {
      authApi
        .getUser(code)
        .then(response => {
          const data = response.data
          const timezone = data?.timezone

          commit('setUser', data)

          if (timezone) {
            moment.tz.setDefault(timezone)
          }

          resolve(data)
        })
        .catch(result => {
          commit('setMessage', result.response.data.message)
          commit('setErrors', result.response.data.errors)
        })
    })
  },
  updateUser({ commit }, code) {
    return authApi
      .updateUser(code)
      .then(response => {
        const data = response.data
        const timezone = data?.timezone

        commit('setUser', data)
        commit('setErrors', {})

        if (timezone) {
          moment.tz.setDefault(timezone)
        }

        return data
      })
      .catch(result => {
        commit('setMessage', result.response.data.message)
        commit('setErrors', result.response.data.errors)
        return Promise.reject(result)
      })
  },
  loginWithGoogle({ commit }) {
    return new Promise(resolve => {
      authApi
        .loginWithGoogle()
        .then(response => {
          resolve(response.data)
        })
        .catch(result => {
          commit('setMessage', result.response.data.message)
          commit('setErrors', result.response.data.errors)
        })
    })
  },
  restorePassRequest({ commit }, email) {
    return authApi.restorePassRequest(email)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        commit('setMessage', error.response.data.message);
        commit('setErrors', error.response.data.errors);
        return Promise.reject(error);
      });
  },
  resetPassword({ commit }, credentials) {
    return authApi
      .resetPassword(credentials)
      .then(response => {
        return response.data
      })
      .catch(error => {
        commit('setMessage', error.response.data.message)
        commit('setErrors', error.response.data.errors)
        return Promise.reject(error);
      })
  },
  clearErrors({ commit }) {
    commit('setErrors', {})
  }
}

const getters = {
  valueByMetrics(state) {
    const metrics = {
      American: {
        height: 'ft',
        weight: 'lb',
      },
      European: { height: 'Cm', weight: 'Kg' },
    }

    return metrics[state.user?.metric]
  },
  profileFilled() {
    return state.user ? state.user && ![
      // state.user.nickname, // now this field is not required
      state.user.name,
      state.user.country.code,
      state.user.timezone,
      state.user.birthday,
      state.user.metric,
      state.user.height,
      state.user.weight,
      state.user.gender,
      state.user.experience,
    ].includes(null) : true
  },
  balance(state) {
    return state?.user?.balance
  },
  isLogin(state) {
    console.log(state.user);
    return !!state.user
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
