import servicesApi from '@/api/services'

const state = {
  isSubmitting: false,
  isLoading: false,
  countries: null,
  errors: null,
  message: null,
}

const mutations = {
  setErrors(state, payload) {
    state.errors = payload
  },
  setCountries(state, payload) {
    state.countries = payload
  },
  setMessage(state, payload) {
    state.message = payload
  },
}

const actions = {
  getCountries({ commit }, credentials) {
    return new Promise(resolve => {
      servicesApi
        .getCountries(credentials)
        .then(response => {
          commit('setCountries', response.data)
          resolve(response.data)
        })
        .catch(result => {
          commit('setMessage', result.message)
          commit('setErrors', result.errors)
        })
    })
  },
  upload({ commit }, file) {
    return new Promise(resolve => {
      servicesApi
        .upload(file)
        .then(response => {
          resolve(response.data)
        })
        .catch(result => {
          commit('setMessage', result.message)
          commit('setErrors', result.errors)
        })
    })
  },
}
const getters = {}

export default {
  state,
  actions,
  mutations,
  getters,
}
