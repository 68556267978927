<template>
  <div id="app">
    <page-update-indicator />
    <router-view />
    <CompleteProfile />
    <payment-result-dialog />
  </div>
</template>

<script>
import CompleteProfile from '@/components/Popup/CompleteProfile'
import { getItem } from '@/helpers/persistanceStorage.js'
import PaymentResultDialog from '@/components/PaymentResultDialog/PaymentResultDialog'
import PageUpdateIndicator from '@/components/PageUpdateIndicator/PageUpdateIndicator'

export default {
  name: 'App',
  components: {
    CompleteProfile,
    PaymentResultDialog,
    PageUpdateIndicator
  },
  metaInfo: {
    meta: [
      { name: 'viewport', content: 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no' },
      { name: 'msapplication-square70x70logo', content: '/favicon/mstile-70x70.png' },
      { name: 'msapplication-square150x150logo', content: '/favicon/mstile-150x150.png' },
      { name: 'msapplication-wide310x150logo', content: '/favicon/mstile-310x150.png' },
      { name: 'msapplication-square310x310logo', content: '/favicon/mstile-310x310.png' },
    ],
    link: [
      { rel: 'apple-touch-icon-precomposed', sizes: "57x57", href: "/favicon/apple-touch-icon-57x57.png" },
      { rel: 'apple-touch-icon-precomposed', sizes: "114x114", href: "/favicon/apple-touch-icon-114x114.png" },
      { rel: 'apple-touch-icon-precomposed', sizes: "72x72", href: "/favicon/apple-touch-icon-72x72.png" },
      { rel: 'apple-touch-icon-precomposed', sizes: "144x144", href: "/favicon/apple-touch-icon-144x144.png" },
      { rel: 'apple-touch-icon-precomposed', sizes: "60x60", href: "/favicon/apple-touch-icon-60x60.png" },
      { rel: 'apple-touch-icon-precomposed', sizes: "120x120", href: "/favicon/apple-touch-icon-120x120.png" },
      { rel: 'apple-touch-icon-precomposed', sizes: "152x152", href: "/favicon/apple-touch-icon-152x152.png" },
      { rel: 'apple-touch-icon-precomposed', sizes: "76x76", href: "/favicon/apple-touch-icon-76x76.png" },
      { rel: 'icon', type: 'image/png', sizes: "196x196", href: "/favicon/favicon-196x196.png" },
      { rel: 'icon', type: 'image/png', sizes: "96x96", href: "/favicon/favicon-96x96.png" },
      { rel: 'icon', type: 'image/png', sizes: "32x32", href: "/favicon/favicon-32x32.png" },
      { rel: 'icon', type: 'image/png', sizes: "16x16", href: "/favicon/favicon-16x16.png" },
      { rel: 'icon', type: 'image/png', sizes: "128x128", href: "/favicon/favicon-128.png" },
    ]
  },
  beforeMount() {
    this.$store.dispatch('newcompetition/restoreState');
    if (getItem('accessToken')) {
      this.$store.dispatch('getUser')
      this.$store.dispatch('notifications/getNotifications')
      this.$store.dispatch('competitions/getInvites')
      this.$store.dispatch('notifications/getNotificationsTexts')
      this.$store.dispatch('loadDialogs')
      this.$store.dispatch('setIntervalDialogLoad')
    }
  },
}
</script>
