<template>
  <button @click='$goBack'><slot/></button>
</template>

<script>
export default {
  name: 'RouterLinkBack',
}
</script>

<style scoped>

</style>
