<template>
  <div
      data-vue-name="CustomHeader"
      class="custom-header"
  >
    <div class="custom-header__title">
      <template v-if='routerBack'>
        <button v-if='routerBackIsFunction' @click='routerBack'>
          <slot name="default"/>
        </button>
        <router-link
          v-if="!routerBackIsFunction"
          :to="routerBack"
        >
          <slot name="default"/>
        </router-link>
      </template>
      <div
          v-else
          class="custom-header--title-click"
          @click="$emit('click')"
      >
        <slot name="default"/>
      </div>
      <slot name="chat"/>
      <slot name="last"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomHeader",
  props: {
    routerBack: {
      type: [String, Function],
      default: null,
    }
  },
  computed: {
    routerBackIsFunction() {
      return typeof this.routerBack === 'function';
    }
  }
}
</script>

<style lang="stylus">
  .custom-header {
    height 60px
    box-shadow: 2px 2px 12px rgb(10 11 16 / 50%)
    border-radius: 0 0 16px 16px;
    padding  0 16px
  }
  .custom-header__title {
    display flex
    align-items center
    justify-content space-between
    height 100%
    font-weight 600
    color #EEE
  }
  .custom-header__title {
    a, .custom-header--title-click {
      font-weight: bold;
      font-size: 20px;
      line-height: 30px;
      font-family: 'Poppins', sans-serif;
      color: #D8D8D8;
      text-decoration none
      cursor pointer
      display: flex;
      align-items: center;
    }
  }
  .custom-header__title a svg {
    margin-right 20px
  }
  .custom-header__title svg {
    margin-right 10px
  }

  .sub-page-settings__title a {
    font-size: 16px;
    line-height: 22px;
    color: #4285F4;
  }
</style>
