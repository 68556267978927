<template>
  <div id="view-content" class="view-content">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'ViewContent'
}
</script>
