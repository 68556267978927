import compApi from '@/api/competition'
import { getTournamentEvent } from '@/api/public/competition'

const state = {
  isSubmitting: false,
  isLoading: false,
  sports: null,
  competition: null,
  users: null,
  opponents: null,
  referees: null,
  answers: {},
  errors: {},
  message: null,
  challenges: null,
  participant: {}
}

const mutations = {
  setParticipant(state, payload) {
    state.participant = payload
  },
  setErrors(state, payload) {
    state.errors = payload
  },
  setUsers(state, payload) {
    state.users = payload
  },
  setCompetition(state, payload) {
    state.competition = payload
  },
  setSports(state, payload) {
    state.sports = payload
  },
  setOpponents(state, payload) {
    state.opponents = payload
  },
  setGenderOpponents(state, payload) {
    state.opponents = payload
  },
  setChallenges(state, payload) {
    state.challenges = payload
  },
  setReferees(state, payload) {
    state.referees = payload
  },
  setMessage(state, payload) {
    state.message = payload
  },
  updateAnswer(state, payload) {
    state.answers = { ...state.answers, ...payload }
  }
}

const actions = {
  async getParticipant({ commit }, payload) {
    try {
      const competitionId = payload?.events?.map(item => item?.id)

      const turnamentEvent = competitionId.map(item =>
        getTournamentEvent(item).then(result => result?.data).catch(error => { console.log('ERROR :' + error); return false })
      )
      const participantInvite = await Promise.allSettled(
        turnamentEvent
      ).then(res => res.map(item => item.value))

      let participant = payload?.events?.map((item, key) => {
        if (item && item?.id === participantInvite[key]?.id) {
          return {
            ...item,
            is_participant: participantInvite[key].is_participant
          }
        }
      })
      participant = participant.filter(el => !!el !== false)
      commit('setParticipant', participant)
    } catch (e) {
      console.log(e)
    }
  },
  getKindSports({ commit }) {
    return new Promise(resolve => {
      compApi
        .getKindSports()
        .then(response => {
          console.log(response.data)
          commit('setSports', response.data)
          resolve(response.data)
        })
        .catch(result => {
          commit('setMessage', result.response.data.message)
          commit('setErrors', result.response.data.errors)
        })
    })
  },
  async getCompetitions({ commit }) {
    try {
      const { data } = await compApi.getCompetitions()

      commit('setCompetition', data)

      return data
    } catch (error) {
      commit('setMessage', error.response.data.message)
      commit('setErrors', error.response.data.errors)
    }

    return null
  },
  getUsers({ commit }) {
    return new Promise(resolve => {
      compApi
        .getUsers()
        .then(response => {
          commit('setUsers', response.data)
          resolve(response.data)
        })
        .catch(result => {
          commit('setMessage', result.response.data.message)
          commit('setErrors', result.response.data.errors)
        })
    })
  },
  getUserByID({ commit, rootState }, id) {
    const method = rootState.auth.user
      ? compApi.getUserByID
      : compApi.getUserByIDGuest
    return new Promise(resolve => {
      method(id)
        .then(response => {
          resolve(response.data)
        })
        .catch(result => {
          commit('setMessage', result.response.data.message)
          commit('setErrors', result.response.data.errors)
        })
    })
  },
  getReferees({ commit }) {
    return new Promise(resolve => {
      compApi
        .getReferees()
        .then(response => {
          commit('setReferees', response.data)
          resolve(response.data)
        })
        .catch(result => {
          commit('setMessage', result.response.data.message)
          commit('setErrors', result.response.data.errors)
        })
    })
  },
  getOpponets({ commit }) {
    return new Promise(resolve => {
      compApi
        .getOpponets()
        .then(response => {
          commit('setOpponents', response.data)
          resolve(response.data)
        })
        .catch(result => {
          commit('setMessage', result.response.data.message)
          commit('setErrors', result.response.data.errors)
        })
    })
  },
  getGenderOpponets({ commit }, gender) {
    return new Promise(resolve => {
      compApi
        .getGenderOpponents(gender)
        .then(response => {
          commit('setGenderOpponents', response.data)
          resolve(response.data)
        })
        .catch(result => {
          commit('setMessage', result.response.data.message)
          commit('setErrors', result.response.data.errors)
        })
    })
  },
  getChallenges({ commit }) {
    return new Promise(resolve =>
      compApi
        .getChallenges()
        .then(response => {
          commit('setChallenges', response.data)
          resolve(response.data)
        })
        .catch(result => {
          commit('setMessage', result.response.data.message)
          commit('setErrors', result.response.data.errors)
        })
    )
  },
  addCompetition({ commit }, data) {
    return new Promise(resolve => {
      compApi
        .sendSportsResult(data)
        .then(response => {
          commit('setCompetition', response.data)
          resolve(response.data)
        })
        .catch(result => {
          commit('setMessage', result.response.data.message)
          commit('setErrors', result.response.data.errors)
        })
    })
  },
  async setApply({ commit }, info) {
    try {
      const { data } = await compApi.setApply(info)
      return data
    } catch (error) {
      commit('setMessage', error.response.data.message)
      commit('setErrors', error.response.data.errors)
    }

    return null
  },
  async setAccept({ commit }, id) {
    try {
      const { data } = await compApi.setAccept(id)

      return data
    } catch (error) {
      commit('setMessage', error.response.data.message)
      commit('setErrors', error.response.data.errors)
    }

    return null
  },
  async setReject({ commit }, id) {
    try {
      const { data } = await compApi.setReject(id)

      return data
    } catch (error) {
      commit('setMessage', error.response.data.message)
      commit('setErrors', error.response.data.errors)
    }

    return null
  }
}

const getters = {
  participant(state) {
    return state.participant
  },
  getSports(state) {
    return state.sports
  },
  getCompetitor(state) {
    return state.opponents
  },
  getUsers(state) {
    return state.users
  },
  getOpponetsId(state) {
    const { challenge } = state.competition[0]
    const { id } = challenge.opponent
    return id
  },
  getCompetitons(state) {
    return state.competition
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
