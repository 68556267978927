import axios from '@/plugins/axios'

const getCountries = () => {
  return axios.get('/profile/countries')
}

const upload = file => {
  return axios.post('/uploads', file)
}

const getShop = () => {
  return axios.get('/public/shop')
}

const getPaymentForm = (productId, resultUrl) => {
  return axios.get(`/shop/get-stripe-payment-url/${productId} `, {
    params: {
      result_url: resultUrl
    }
  })
}

const getPaymentTransaction = (transactionId) => {
  return axios.get(`/shop/transaction-info?order_id=${transactionId}`)
}

const checkEnoughBalanceToCreateCompetition = (totalPrice) => {
  return axios.get(`/profile/me/check-balance`, {
    params: {
      count_points: totalPrice
    }
  })
}

export default {
  getCountries,
  upload,
  getShop,
  getPaymentForm,
  getPaymentTransaction,
  checkEnoughBalanceToCreateCompetition
}
