import rating from '@/api/rating'

const state = {
  ratings: [],
  users: []
}

const mutations = {
  setErrors(state, payload) {
    state.errors = payload
  },
  setUsers(state, payload) {
    state.users = payload
  },
  setRatingsUsers(state, payload) {
    state.ratings = payload
  },
  setMessage(state, payload) {
    state.message = payload
  }
}

const actions = {
  getUser({ commit, dispatch }) {
    return new Promise(resolve => {
      rating
        .getUsersInfo()
        .then(response => {
          commit('setUsers', response.data)
          resolve(response.data)
          dispatch('home/restoreViewedVerdicts');
          dispatch('competitions/restoreViewedInvites');
          dispatch('home/startIntervalCheckVerdicts');
          dispatch('competitions/startIntervalCheckInvites');
          dispatch('notifications/getNotifications')
        })
        .catch(result => {
          commit('setMessage', result.message)
          commit('setErrors', result.errors)
        })
    })
  },
  getRatingUser({ commit }) {
    return new Promise(resolve => {
      rating
        .getGeneralRating()
        .then(response => {
          commit('setRatingsUsers', response.data)
          resolve(response.data)
        })
        .catch(result => {
          commit('setMessage', result.message)
          commit('setErrors', result.errors)
        })
    })
  }
}

const getters = {}

export default {
  state,
  actions,
  mutations,
  getters,
}
