<template>
  <div v-if='this.$swipeUpdate.count' class='page-update-indicator'
       :class='{"page-update-indicator__fetching": $swipeUpdate.fetching}' :style='containerStyles'>
    <svg class='page-update-indicator__svg' xmlns="http://www.w3.org/2000/svg"
         viewBox="-1 -1 34 34">
      <circle cx="16" cy="16" r="15.9155"
              class="page-update-indicator__background" :style='indicatorBackgroundStyles'/>
      <circle cx="16" cy="16" r="15.9155"
              class="page-update-indicator__progress" :style='indicatorStyles' />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'PageUpdateIndicator',
  data() {
    return {
      yStartSwipe: null, // y position of start swipe
      /**
       * Dynamic styles
       */
      containerStyles: {},
      indicatorStyles: {},
      indicatorBackgroundStyles: {}
    }
  },
  watch: {
    '$swipeUpdate.fetching'(value) {
      if (!value) {
        this.handleTouchEnd();
      }
    }
  },
  mounted() {
    document.addEventListener('touchstart', this.handleTouchStart, false);
    document.addEventListener('touchend', this.handleTouchEnd, false);
    document.addEventListener('touchmove', this.handleTouchMove, false);
  },
  beforeDestroy() {
    document.removeEventListener('touchstart', this.handleTouchStart);
    document.removeEventListener('touchend', this.handleTouchEnd);
    document.removeEventListener('touchmove', this.handleTouchMove);
  },
  methods: {
    checkContainer() {
      const ViewContentNode = document.getElementById('view-content');
      return ViewContentNode && ViewContentNode.scrollTop===0
    },
    handleTouchStart(evt) {
      if (!this.$swipeUpdate.count || this.$swipeUpdate.fetching || !this.checkContainer()) {
        return
      }
      const firstTouch = evt.touches[0];
      this.yStartSwipe = firstTouch.clientY;
    },
    handleTouchEnd() {
      if (!this.$swipeUpdate.count || this.$swipeUpdate.fetching) {
        return
      }
      this.$set(this, 'containerStyles', {
        transform: `translate(-50%, -100%) scale(0.7)`
      })
      this.$set(this, 'indicatorBackgroundStyles', {
        opacity: 0
      })
    },
    handleTouchMove(evt) {

      if (!this.$swipeUpdate.count || this.$swipeUpdate.fetching || !this.checkContainer() || this.yStartSwipe===null) {
        return
      }

      const touches = evt.touches[0];
      const posY = touches.clientY
      const yDiff = this.yStartSwipe - posY;

      // Only swipe down
      if (yDiff >= 0) {
        return;
      }

      const progressDistance = (Math.abs(yDiff)/300)*100
      const progressMax = progressDistance > 100 ? 100 : progressDistance

      this.$set(this, 'containerStyles', {
        transform: `translate(-50%, ${progressMax + 60}%) scale(${0.85 + (0.15 * progressMax) / 100})`,
        opacity: progressDistance / 50,
      })

      this.$set(this, 'indicatorBackgroundStyles', {
        opacity: progressDistance / 50
      })

      this.$set(this, 'indicatorStyles', {
        strokeDashoffset:  100 - progressMax
      })

      if (Math.abs(progressDistance) >= 100) {
        this.$swipeUpdateCall();
      }

    }
  },
}
</script>

<style scoped>
.page-update-indicator {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 99999;
  transform: translate(-50%, -100%);
  --page-update-indicator-size: 50px;
  --page-update-indicator-stroke-width: 1.8;
  transition: transform 0.1s;
}
.page-update-indicator__fetching {
  animation: page-update-indicator-loading 0.5s linear;
  animation-iteration-count: infinite;
}
.page-update-indicator__fetching .page-update-indicator__progress {
  stroke-dasharray: 100 50 !important;
  stroke-dashoffset: 50 !important;
}
.page-update-indicator__svg {
  height: var(--page-update-indicator-size);
  transform: rotate(-90deg);
  width: var(--page-update-indicator-size);
}
.page-update-indicator__background {
  fill: none;
  stroke: #7B8B97;
  stroke-width: var(--page-update-indicator-stroke-width);
}
.page-update-indicator__progress {
  fill: none;
  stroke: #d21341;
  stroke-dasharray: 100 100;
  stroke-dashoffset: 100;
  stroke-linecap: round;
  stroke-width: var(--page-update-indicator-stroke-width);
  transition: stroke-dashoffset 0.05s ease-in-out;
}
@keyframes page-update-indicator-loading {
  from {
    transform: translate(-50%, 160%) rotate(0deg) scale(1);
  }
  to {
    transform: translate(-50%, 160%)  rotate(360deg) scale(1);
  }
}
</style>
