import axios from '@/plugins/axios'

export function getCompetition (id) {
  return axios.get(`/public/competitions/${id}`)
}

export function getCompetitions () {
  return axios.get('/public/competitions')
}

export function getTournament (id) {
  return axios.get(`/public/tournaments/${id}`)
}

export function getEvents () {
  return axios.get('/public/events')
}

export function getEvent (id) {
  return axios.get(`/public/events/${id}`)
}

export function getTournamentEvent (id) {
  return axios.get(`/public/tournaments/events/${id}`)
}


export function getVerdicts () {
  return axios.get('/public/competitions/verdicts')
}

export function getVerdict (id) {
  return axios.get(`/public/competitions/verdict/${id}`)
}

export function getTournamentsVerdicts () {
  return axios.get(`/public/tournaments/verdicts`)
}
export function getTournamentsVerdict (id) {
  return axios.get( `/public/tournaments/verdicts/${id}`)
}
export function getTournamentsEventVerdict (event_id) {
  return axios.get( `/public/tournaments/events/${event_id}/verdict`)
}
export function getEventsVerdicts () {
  return axios.get(`/public/events/verdicts`)
}
export function getEventsResults (id) {
  return axios.get(`/public/events/${id}/results`)
}
export function getTournamentsResults (id) {
  return axios.get(`/public/tournaments/events/${id}/results`)
}
export function getEventsVerdict (id) {
  return axios.get(`/public/events/${id}/verdict`)
}
export function getTournaments () {
  return axios.get('/public/tournaments')
}


export default {
  getCompetition,
  getCompetitions,
  getVerdicts,
  getVerdict,
  getTournamentsVerdicts,
  getTournamentsVerdict,
  getTournamentsEventVerdict,
  getEventsVerdicts,
  getEventsVerdict,
  getTournaments,
  getTournament,
  getEvents,
  getEvent,
  getTournamentEvent,
}
