import router from '../router';

export default {
  install: function (Vue) {
    Vue.prototype.$goBack = function() {
      return window.history.length > 2 ? router.back() : router.push({name: 'home'})
    }
  }

}
