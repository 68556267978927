<template>
  <div class="view-header" :class="classes">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'ViewHeader',
  props: {
    fixed: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classes () {
      const { fixed } = this

      return { fixed }
    }
  }
}
</script>