<template>
  <div data-vue-name="FooterCompetitions" class="footer-competitions">
    <div class="footer-competitions__wrap">
      <ul>
        <li>
          <router-link to="/home" class="footer-competitions__link">
            <Icon :type="'menu-home'" :size="24" />
          </router-link>
        </li>
        <li ref="hasNewInvites" :class="{ event: hasNotifications }">
          <router-link to="/notifications" class="footer-competitions__link">
            <Icon :type="'menu-notification'" :size="24" />
          </router-link>
        </li>
        <li>
          <router-link exact-path :to="{ name: user ? 'competitions' : 'authorization' }"
            class="footer-competitions__link">
            <Icon :type="'menu-sword'" :size="24" />
          </router-link>
        </li>
        <li :class="isUserHaveNewMessages && 'event'">
          <router-link to="/chat" class="footer-competitions__link">
            <Icon :type="'menu-chat'" :size="24" />
          </router-link>
        </li>
        <li>
          <router-link :to="isLogin ? '/account' : '/authorization'" class="footer-competitions__link">
            <Icon :type="'menu-account'" :size="24" />
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Icon from '@/components/Icon'

export default {
  name: 'FooterCompetitions',
  components: {
    Icon
  },
  mounted() {
    this.$refs['hasNewInvites'].classList.value = ''
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      dialogs: state => state.chat.dialogs,
    }),
    ...mapState('notifications', ['notifications']),
    ...mapState('competitions', {
      invites: ({
        invites
      }) => invites
    }),
    hasNotifications() {
      return this.notifications.find(notification => !notification.read_at)
    },
    ...mapGetters({
      isLogin: 'isLogin',
    }),
    isUserHaveNewMessages() {
      return this.dialogs.find(dialog => !dialog.is_read && this.user?.id === dialog.to_user_id)
    },
    basketballStatus() {
      return this.basketballInvites.filter(el => (el.status === 'Waiting')).length > 0
    },
    swimmingInvites() {
      return this.invites.filter(el => (el.competition?.kind_sport.name === "Swimming")).sort((a, b) => {
        if (a.status !== 'Waiting' && b.status === 'Waiting') return 1
        if (a.status === 'Waiting' && b.status !== 'Waiting') return -1
        return 0
      });
    },
    basketballInvites() {
      return this.invites.filter(el => (el.competition?.kind_sport.name === "Basketball")).sort((a, b) => {
        if (a.status !== 'Waiting' && b.status === 'Waiting') return 1
        if (a.status === 'Waiting' && b.status !== 'Waiting') return -1
        return 0
      });
    }
  },
  methods: {
    isInactive(invite) {
      return (invite.competition.status === 'performances-are-marked-up'
        || invite.competition.status === 'judging-is-done'
        || invite.competition.status === 'video-post-prod-is-done'
        || invite.competition.status === 'video-is-scheduled')
    },
  },
  watch: {
    invites(newVal) {
      for (let inv of newVal.filter(el => (el.competition?.kind_sport.name === "Swimming"))) {
        if (inv.status === 'Waiting' && !this.isInactive(inv)) {
          if (inv.competition.challenge_status !== 'overdue') {
            this.$refs['hasNewInvites'].classList.value = 'event'
          }

        }

      }
    }
  }
}
</script>

<style lang="stylus" scoped>
  .footer-competitions {
    ul {
      padding: 8px 16px 16px;
      background: #1D2130;
      box-shadow: 0 0 12px rgba(25, 26, 30, 0.5);
      border-radius: 16px 16px 0 0;
      //overflow: hidden;
      margin: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    li {
      position: relative;

      &.event:after {
        content: '';
        display: block;
        position: absolute;
        background: #6293FF;
        width: 8px;
        height: 8px;
        top: 4px;
        right: 4px;
        border-radius: 50%;
      }
    }

    &__link {
      display flex
      justify-content center
      align-items center
      width 40px
      height 40px
      color #7B8B97

      &:hover {
        color #7B8B97
      }

      &.router-link-active {
        color #D21341
      }
    }
  }
</style>
