<template>
  <svg :class="[className, size]">
    <use :xlink:href="require('../assets/img/svg-sprite.svg') + '#' + name"></use>
  </svg>
</template>

<script>
export default {
  name: "SvgIcon",
  props: {
    name: {
      name: String,
      default: null,
    },
    size: {
      type: String,
      default: null,
    },
    className: {
      type: String,
      default: null,
    },
  },
}
</script>
