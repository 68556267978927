<template>
  <div
      data-vue-name="HeaderCompetitions"
      class="header"
  >
    <div class="header__title"><slot/></div>
    <div class="header__search-box">
      <router-link
          data-vue-name="BtnAddCompetition"
          class=""
          to="/search"
      >
        <button class="header__show-search-btn">
          <svg class="search-line">
            <use xlink:href="../assets/img/svg-sprite.svg#searchLine"></use>
          </svg>
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>

export default {
  name: "HeaderCompeta",
}
</script>

<style lang="stylus">
.header {
  display: flex;
  justify-content: space-between;
  align-items center
  padding 16px
  background: #141723;
  box-shadow: 0 0 12px rgba(25, 26, 30, 0.5);
  border-radius: 0 0 16px 16px;
  overflow hidden
  position sticky
  top 0
  z-index: 100;
  width 100%
}

.header__title {
  font-family: Poppins;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: #D8D8D8;
}

.header__status-acc {
  position absolute
  left: 50%
  top: 50%
  transform translate(-50%, -50%)
}

.header__status-acc > * {
  display flex
  align-items center
  justify-content center
  text-decoration none
  text-transform uppercase
  color: #141723;
}

.search-line {
  width 21px
  height 21px
}
</style>
