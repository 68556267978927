import { getDialogs } from '@/api/chat'

const state = {
  dialogs: [],
  dialogUpdater: null
}

const mutations = {
  SET_DIALOGS(state, dialogs) {
    state.dialogs = dialogs
  },
  SET_DIALOG_UPDATER(state, interval) {
    state.dialogUpdater = interval
  }
}

const actions = {
  async loadDialogs({ commit }) {
    try {
      const req = await getDialogs()
      const arrayFromReq = Object.values(req.data)
      commit('SET_DIALOGS', arrayFromReq)
    } catch (e) {
      console.log(e)
    }
  },
  setIntervalDialogLoad({ dispatch, commit, rootGetters, state }) {
    if (rootGetters.isLogin && state.dialogUpdater === null) {
      const dialogInterval = setInterval(() => {
        dispatch('loadDialogs')
        console.log('GET DIALOGS INTERVAL');
      }, 30000)
      commit('SET_DIALOG_UPDATER', dialogInterval)
    }
  },
  stopIntervalDialogLoad({ state }) {
    state.dialogUpdater && clearInterval(state.dialogUpdater)
  }
}

export default {
  state,
  mutations,
  actions
}
