import axios from '@/plugins/axios'

const register = credentials => {
  return axios.post('/register', credentials)
}

const login = credentials => {
  return axios.post('/login', credentials)
}

const restorePassRequest = credentials => {
  return axios.post('/password/forgot', credentials)
}

const resetPassword = credentials => {
  return axios.post('/password/reset', credentials)
}

const loginWithGoogle = () => {
  return axios.get('/login/google')
}

const loginByGoogleCreds = credentials => {
  return axios.get(`/login/google/callback${credentials}`)
}

const confirmRegistrationByMail = ({ id, hash, expires, signature }) => {
  return axios.get(
    `/email/verify/${id}/${hash}?expires=${expires}&signature=${signature}`,
  )
}

const logout = () => {
  return axios.post('/logout')
}
const getUser = () => {
  return axios.get('/profile/me')
}
const updateUser = data => {
  return axios.post('/profile/me', data)
}
export default {
  register,
  logout,
  login,
  getUser,
  updateUser,
  loginWithGoogle,
  loginByGoogleCreds,
  restorePassRequest,
  resetPassword,
  confirmRegistrationByMail,
}
