import axios from '@/plugins/axios'

const getGeneralRating = () => {
  return axios.get('/users/rating')
}
const getUsersInfo = () => {
  return axios.get('/profile/users')
}

export default {
  getGeneralRating,
  getUsersInfo
}