import competition from '@/api/competition'

const {
  getCompetitions,
  getChallenges,
  getTournaments,
  getEvents
} = competition

function defaultState() {
  return {
    invites: [],
    viewedInvites: [],
    intervalCheckInvitesID: null,
    competitions: [],
    tournaments: [],
    events: [],
  }
}

const state = defaultState()

const getters = {
  existUnseenInvites(state) {
    if (!state.viewedInvites.length && state.invites.length) {
      return true;
    } else {
      return state.invites.filter(item => {
        return state.viewedInvites.indexOf(item.id) === -1
      }).length !== 0
    }
  }
}

const mutations = {
  SET_INVITES(state, invites) {
    state.invites = invites
  },
  SET_COMPETITIONS(state, competition) {
    state.competitions = competition
  },
  SET_TOURNAMENTS(state, tournaments) {
    state.tournaments = tournaments
  },
  SET_EVENTS(state, events) {
    state.events = events
  },
  RESET_STORE(state) {
    Object.assign(state, defaultState())
  },
  SET_CHECK_INVITES_INTERVAL_ID(state, setIntervalId) {
    state.intervalCheckInvitesID = setIntervalId
  },
  SET_VIEWED_INVITES_IDS(state, invitesIds) {
    state.viewedInvites = invitesIds;
  }
}

const actions = {
  reset({
    commit
  }) {
    commit('SET_COMPETITIONS', [])
    commit('SET_INVITES', [])
  },
  async getCompetitions({
    commit
  }) {
    try {
      const {
        data: competitions
      } = await getCompetitions()

      commit('SET_COMPETITIONS', competitions)
      return competitions
    } catch (e) {
      commit('SET_COMPETITIONS', [])
      return []
    }
  },
  async getInvites({
    commit,
    rootGetters
  }) {
    console.log('GET INVITES REQ', rootGetters);
    try {
      const {
        data: invites
      } = await getChallenges()

      commit('SET_INVITES', invites)
    } catch (e) {
      commit('SET_INVITES', [])
    }
  },
  async getTournaments({
    commit
  }) {
    try {
      const {
        data: competitions
      } = await getTournaments()

      commit('SET_TOURNAMENTS', competitions)
      return competitions
    } catch (e) {
      commit('SET_TOURNAMENTS', [])
      return []
    }
  },
  async getEvents({
    commit
  }) {
    try {
      const {
        data: competitions
      } = await getEvents()

      commit('SET_EVENTS', competitions)
      return competitions
    } catch (e) {
      commit('SET_EVENTS', [])
      return []
    }
  },
  async init({ dispatch }) {
    await dispatch('getCompetitions')
    await dispatch('getEvents')
    await dispatch('getTournaments')
  },
  resetStore({
    commit
  }) {
    commit('RESET_STORE')
  },
  startIntervalCheckInvites({
    dispatch,
    rootGetters,
    commit,
  }) {
    if (rootGetters.isLogin) {
      const invervalId = setInterval(() => {
        if (rootGetters.isLogin) {
          dispatch('getInvites')
          console.log('INVITES INTERVAL');
        }
      }, 30000)
      commit('SET_CHECK_INVITES_INTERVAL_ID', invervalId);
    }
  },
  stopIntervalCheckInvites({
    state
  }) {
    if (state.intervalCheckInvitesID !== null) {
      clearInterval(state.intervalCheckInvitesID)
    }
  },
  addAllToViewedInvites({
    commit,
    state,
    getters
  }) {
    if (!getters.existUnseenInvites) {
      return;
    }
    const viewedIntitesIds = state.invites.map(item => item.id);
    commit('SET_VIEWED_INVITES_IDS', viewedIntitesIds);
    try {
      localStorage.setItem('viewedInvites', JSON.stringify(viewedIntitesIds))
    } catch (Error) {
      console.error(Error)
    }
  },
  restoreViewedInvites({
    commit
  }) {
    if (localStorage.getItem('viewedInvites') === null) {
      return;
    }
    try {
      const viewedInvitesIds = JSON.parse(localStorage.getItem('viewedInvites'));
      commit('SET_VIEWED_INVITES_IDS', viewedInvitesIds);
    } catch (Error) {
      console.error(Error)
    }
  }
}

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true
}