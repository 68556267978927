/**
 * Plugin for updating page after swipe down
 */

export default {
  install: function (Vue) {

    /**
     * Reactive data
     */
    const $swipeUpdate = new Vue({
      data() {
        return {
          count: 0,
          fetching: 0,
        }
      }
    })

    /**
     * Event listeners on swipe down
     */
    let listeners = []

    Vue.mixin({
      mounted() {
        // Check swipeUpdate property
        if (this.$options.swipeUpdate) {
          // Bind swipeUpdate to the component context
          const originalHandler = this.$options.swipeUpdate.bind(this);

          // Wrap swipeUpdate for watching for tracking progress
          this.$options.swipeUpdate = function() {
            $swipeUpdate.fetching ++;
            const handlerResult = originalHandler()
            // Wait if handler's result is the Promise
            if (handlerResult instanceof Promise) {
              handlerResult.finally(() => {
                $swipeUpdate.fetching--;
              })
            } else {
              $swipeUpdate.fetching--;
            }
          }
          // Add to the event listener's list
          listeners.push(this.$options.swipeUpdate)
          $swipeUpdate.count++
        }
      },
      computed: {
        /**
         * Global reactive data
         */
        $swipeUpdate() {
          return {
            // Count event listeners
            count: $swipeUpdate.count,
            // Count fetching components
            fetching: $swipeUpdate.fetching
          }
        }
      },
      methods: {
        /**
         * Call all the event listeners
         */
        $swipeUpdateCall() {
          listeners.forEach(listener => listener())
        }
      },
      beforeDestroy() {
        /**
         * Remove event listener when component destroy
         */
        if (this.$options.swipeUpdate) {
          listeners = listeners.filter(listener => listener!== this.$options.swipeUpdate)
          $swipeUpdate.count--
        }
      }
    })
  }

}
