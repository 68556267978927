function defaultState () {
  return {
    id: localStorage.getItem('invitation_event_id') || null,
    type: localStorage.getItem('invitation_event_type') || null,
    hash: localStorage.getItem('invitation_event_hash') || null,
  }
}

const state = defaultState()

const mutations = {
  SET_ID (state, id) {
    localStorage.setItem('invitation_event_id', id)
    state.id = id
  },
  SET_TYPE (state, type) {
    localStorage.setItem('invitation_event_type', type)
    state.type = type
  },
  SET_HASH (state, hash) {
    localStorage.setItem('invitation_event_hash', hash)
    state.hash = hash
  },
  RESET_HASH(state) {
    localStorage.removeItem('invitation_event_hash')
    state.hash = null
  },
  RESET(state){
    localStorage.removeItem('invitation_event_id')
    localStorage.removeItem('invitation_event_type')
    localStorage.removeItem('invitation_event_hash')
    state.id = null
    state.type = null
    state.hash = null
  }
}

const actions = {
  set({ commit }, { id, type, hash }){
    commit("SET_ID", id)
    commit("SET_TYPE", type)
    commit("SET_HASH", hash)
  },
  reset ({ commit }) {
    commit('RESET')
  }
}

export default {
  state,
  actions,
  mutations,
  namespaced: true
}