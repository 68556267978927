<template>
  <Popup :open='isShowPopupSuccess' @close='isShowPopupSuccess = false'>
    <template v-slot:default>
      <div v-if='!paymentResult' class='start-storage__payment-fetching'>
        Loading...
      </div>
      <template v-if='paymentResult  && paymentResult.status==="success" && paymentResultProduct'>
        <div class='start-storage__success-header'>
          <div class='start-storage__success-img'>
            <img :src='paymentResultProduct.image_url' alt=''>
          </div>
          <div class='start-storage__success-count'>
            +{{paymentResultProduct.count_points}} stars
          </div>
        </div>
        <h2 class='start-storage__success-heading'>Great! Your balance has been replenished!</h2>
        <p class='start-storage__success-comment'>Your balance is <svg-icon size="size16x16" name="icoCoin" /> {{balance}}</p>
      </template>
      <div v-if='paymentResultError' class='start-storage__payment-error'>Error</div>
    </template>
    <template v-slot:actions>
      <a-button @click='isShowPopupSuccess = false' :disabled='isFetching > 0'> <Icon :size="20" type="check"/> Continue</a-button>
    </template>
  </Popup>
</template>

<script>
import { mapGetters } from 'vuex'
import services from '@/api/services'
import Popup from '@/components/Popup'
import Icon from '@/components/Icon.vue'

export default {
  name: 'PaymentResultDialog',
  components: {Popup, Icon},
  data() {
    return {
      shop: [],
      paymentForm: null,
      isShowPopupSuccess: false,
      paymentResult: false,
      paymentResultError: false,
      isFetching: 0
    }
  },
  computed: {
    paymentResultProduct() {
      if (!this.paymentResult) {
        return null
      }
      const product = this.shop.find(item => item.id === this.paymentResult.shop_id)
      if (!product) {
        return null
      }
      return product
    },
    ...mapGetters({
      'balance': 'balance'
    })
  },
  watch: {
    '$route.query.order_id'(value) {
      if (value!==undefined) {
        if (!this.isFetching) {
          this.isShowPopupSuccess = true
          this.isFetching++
          services.getShop()
            .then(response => {
              this.shop = response.data;
            })
            .then(() => {
              return services.getPaymentTransaction(this.$route.query.order_id).then(response => {
                this.paymentResult = response.data
              })
            })
            .catch(() => {
              this.paymentResultError = true
              console.log('this.paymentResultError: ', this.paymentResultError);
            })
            .finally(() => {
              this.isFetching--
            })
        }

      }
    }
  },
}
</script>

<style scoped>
.start-storage__payment-fetching {
  text-align: center;
  font-size: 1.3rem;
}
.start-storage__success-header {
  display: flex;
  align-items: center;
  justify-content: center;
}
.start-storage__success-img {
  width: 50%;
  max-width: 116px;
  margin-right: 15px;
}
.start-storage__success-img img {
  max-width: 100%;
}
.start-storage__success-count {
  font-weight: bold;
  font-size: 24px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: #FFFFFF;
}
.start-storage__success-heading {
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: #D8D8D8;
  margin: 0;
  margin-bottom: 8px;
  margin-top: 8px;
}
.start-storage__success-comment {
  font-size: 16px;
  line-height: 24px;
  color: #D8D8D8;
}
.start-storage__payment-error {
  text-align: center;
  color: #ff5151;
  font-size: 1.3rem;
}
</style>
