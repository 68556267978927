import {
  getCompetitions,
  getEvents,
  getTournaments,
  getVerdicts,
  getTournamentsVerdicts,
  getEventsVerdicts
} from '@/api/public/competition'

function defaultState() {
  return {
    verdicts: [],
    tournamentVerdicts: [],
    eventsVerdicts: [],
    competitions: [],
    tournaments: [],
    events: [],
    intervalCheckVerdictsID: null,
    viewedVerdicts: [],
    challenges: {
      selectedList: [],
      list: [
        {
          text: 'Upcoming',
          key: 'upcoming'
        },
        {
          text: 'Streamed',
          key: 'streamed'
        },
      ],
    },
    sportView: {
      selected: 0,
      selectedList: [],
      list: [{
        image: require('@/assets/img/bank1.png'),
        text: 'Swimming',
        key: 'swimming'
      },
      {
        image: require('@/assets/img/basketball.png'),
        text: 'Basketball',
        key: 'basketball'
      },
      {
        image: require('@/assets/images/workout.png'),
        text: 'Workout',
        key: 'workout'
      },
      ],
    }
  }
}

const state = defaultState()

const getters = {
  existUnseenVerdicts(state) {
    if (!state.viewedVerdicts.length && state.verdicts.length) {
      return true;
    } else {
      return state.verdicts.filter(item => {
        return state.viewedVerdicts.indexOf(item.id) === -1
      }).length !== 0
    }
  },
  selectedSport(state) {
    return state.sportView.list[state.sportView.selected]
  }
}

const mutations = {
  SET_VERDICTS(state, verdicts) {
    state.verdicts = verdicts
  },
  SET_TOURNAMENTS_VERDICTS(state, tournamentVerdicts) {
    state.tournamentVerdicts = tournamentVerdicts
  },
  SET_EVENTS_VERDICTS(state, eventsVerdicts) {
    state.eventsVerdicts = eventsVerdicts
  },
  SET_COMPETITIONS(state, competition) {
    state.competitions = competition
  },
  SET_TOURNAMENTS(state, tournaments) {
    state.tournaments = tournaments
  },
  SET_EVENTS(state, events) {
    state.events = events
  },
  RESET_STORE(state) {
    Object.assign(state, defaultState())
  },
  SET_CHECK_VERDICTS_INTERVAL_ID(state, setIntervalId) {
    state.intervalCheckVerdictsID = setIntervalId
  },
  SET_VIEWED_VERDICTS_IDS(state, competitionsIds) {
    state.viewedVerdicts = competitionsIds;
  },
  SET_SELECTED_SPORT(state, index) {
    state.sportView.selected = index
  },
  SET_SELECTED_SPORTS(state, items) {
    state.sportView.selectedList = [...items];
  },
  SET_SELECTED_CHALLENGES(state, items) {
    state.challenges.selectedList = [...items];
  },
}

const actions = {
  async getCompetitions({
    commit
  }) {
    try {
      const {
        data: competitions
      } = await getCompetitions();

      commit('SET_COMPETITIONS', competitions)

      return competitions

    } catch (e) {
      commit('SET_COMPETITIONS', [])
    }
  },
  async getTournaments({
    commit
  }) {
    try {
      const {
        data: tournaments
      } = await getTournaments();

      commit('SET_TOURNAMENTS', tournaments)

      return tournaments

    } catch (e) {
      commit('SET_TOURNAMENTS', [])
    }
  },
  async getEvents({
    commit
  }) {
    try {
      const {
        data: events
      } = await getEvents();

      commit('SET_EVENTS', events)

      return events

    } catch (e) {
      commit('SET_EVENTS', [])
    }
  },
  async getVerdicts({
    commit
  }) {
    try {
      const {
        data: results
      } = await getVerdicts()

      commit('SET_VERDICTS', results)

      return results

    } catch (e) {
      commit('SET_VERDICTS', [])
    }
  },
  async getTournamentsVerdicts({
    commit
  }) {
    try {
      const {
        data: results
      } = await getTournamentsVerdicts()

      commit('SET_TOURNAMENTS_VERDICTS', results)

      return results

    } catch (e) {
      commit('SET_TOURNAMENTS_VERDICTS', [])
    }
  },
  async getEventsVerdicts({
    commit
  }) {
    try {
      const {
        data: results
      } = await getEventsVerdicts()

      commit('SET_EVENTS_VERDICTS', results)

      return results

    } catch (e) {
      commit('SET_EVENTS_VERDICTS', [])
    }
  },
  async init({ dispatch }) {
    await dispatch('getCompetitions')
    await dispatch('getVerdicts')
    await dispatch('getEvents')
    await dispatch('getEventsVerdicts')
    await dispatch('getTournaments')
    await dispatch('getTournamentsVerdicts')
  },
  resetStore({
    commit
  }) {
    commit('RESET_STORE')
  },
  startIntervalCheckVerdicts({
    dispatch,
    rootGetters,
    commit
  }) {
    if (rootGetters.isLogin) {
      const invervalId = setInterval(() => {
        if (rootGetters.isLogin) {
          dispatch('getVerdicts')
          console.log('VERDICTS INTERVAL');
        }
      }, 30000)
      commit('SET_CHECK_VERDICTS_INTERVAL_ID', invervalId);
    }
  },
  stopIntervalCheckVerdicts({
    state
  }) {
    if (state.intervalCheckVerdictsID !== null) {
      clearInterval(state.intervalCheckVerdictsID)
    }
  },
  addAllToViewedVerdicts({
    commit,
    state,
    getters
  }) {
    if (!getters.existUnseenVerdicts) {
      return;
    }
    const viewedVerdictsIds = state.verdicts.map(item => item.id);
    commit('SET_VIEWED_VERDICTS_IDS', viewedVerdictsIds);
    try {
      localStorage.setItem('viewedVerdicts', JSON.stringify(viewedVerdictsIds))
    } catch (Error) {
      console.error(Error)
    }
  },
  restoreViewedVerdicts({
    commit
  }) {
    if (localStorage.getItem('viewedVerdicts') === null) {
      return;
    }
    try {
      const viewedVerdictsIds = JSON.parse(localStorage.getItem('viewedVerdicts'));
      commit('SET_VIEWED_VERDICTS_IDS', viewedVerdictsIds);
    } catch (Error) {
      console.error(Error)
    }
  }
}

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true
}




