import { read, getNotifications, getNotificationsTexts } from '@/api/notifications'

function defaultState() {
  return {
    notifications: [],
    notificationsTexts: []
  }
}

const state = defaultState()

const getters = {
  GET_NOTIFICATIONS_TEXTS(state) {
    return state.notificationsTexts
  }

}

const mutations = {
  SET_NOTIFICATIONS(state, notifications) {
    state.notifications = notifications
  },
  SET_NOTIFICATIONS_TEXTS(state, notificationsTexts) {
    state.notificationsTexts = notificationsTexts
  },
  RESET_STORE(state) {
    Object.assign(state, defaultState())
  }
}

const actions = {
  async getNotifications({ commit }) {
    try {
      const { data: notifications } = await getNotifications()

      commit('SET_NOTIFICATIONS', notifications)
    } catch (e) {
      commit('SET_NOTIFICATIONS', [])
    }
  },
  async getNotificationsTexts({ commit }) {
    try {
      const { data: notificationsTexts } = await getNotificationsTexts()

      commit('SET_NOTIFICATIONS_TEXTS', notificationsTexts)
    } catch (e) {
      commit('SET_NOTIFICATIONS_TEXTS', [])
    }
  },
  async read(_, id) {
    try {
      await read(id)
    } catch (e) {
      console.log(e)
    }
  },
  resetStore({ commit }) {
    commit('RESET_STORE')
  }
}

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true
}
