<template>
  <Popup :open="open" :height="popupHeight" @close="open = false">
<!--    <h4>{{ $t('popup.completeprofile.title') }}</h4>-->

<!--    <p>{{ $t('popup.completeprofile.description') }}</p>-->
    <h4>{{ $t('popup.newcompetition.title') }}</h4>

    <p>{{ $t('popup.newcompetition.description') }}</p>

    <ul>
      <li>{{ $t('popup.newcompetition.fields.personalInfo') }}</li>
      <li>{{ $t('popup.newcompetition.fields.physiologicalData') }}</li>
    </ul>

    <template slot="actions">
      <a-button @click="goToSettings">
        <Icon :size="17" type="check" />

        {{ $t('popup.completeprofile.ok') }}
      </a-button>
    </template>
  </Popup>
</template>

<script>
import Icon from '@/components/Icon'
import Popup from '@/components/Popup'
import { mapState, mapGetters } from 'vuex'
// import { getItem, setItem } from '@/helpers/persistanceStorage.js'

export default {
  name: 'CompleteProfile',
  components: {
    Icon,
    Popup
  },
  data () {
    return {
      open: false,
    }
  },
  created () {
    this.$watch('user', () => {
      // const openedBefore = getItem('profileCompletePopup')
      this.open = !this.profileFilled && !this.$route.path.includes('settings') && !this.$route.path.includes('personal-info') && !this.$route.path.includes('physiological-data')
      // if (this.user && !this.profileFilled && !openedBefore && !this.invitationId) {
      //   this.togglePopup()
      //
      //   setItem('profileCompletePopup', true)
      // }
    }, {
      deep: true,
      immediate: true
    })
  },
  computed: {
    ...mapGetters(['profileFilled']),
    ...mapState({
      user: ({ auth }) => auth.user,
      invitationId: ({ eventHash }) => eventHash.id
    }),
    fields () {
      const fields = ['timezone', 'nickname']
      const unsavedFields = fields.filter((field) => {
        return this.user?.[field] === null
      })
      return [...unsavedFields, 'physiologicalData']
    },
    popupHeight () {
      return 270 + (this.fields.length * 22)
    },
  },
  methods: {
    goToSettings() {
      this.open = false
      this.$router.push({ name: 'settings' })
    }
  }
}
</script>
