import Vue from 'vue'
import Vuex from 'vuex'

import auth from '@/store/modules/auth'
import home from '@/store/modules/home'
import services from '@/store/modules/services'
import competition from '@/store/modules/competition'
import competitions from '@/store/modules/competitions'
import newcompetition from '@/store/modules/newcompetition'
import rating from '@/store/modules/rating'
import search from '@/store/modules/search'
import notifications from '@/store/modules/notifications'
import chat from '@/store/modules/chat'
import eventHash from '@/store/modules/eventHash'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    home,
    services,
    competition,
    competitions,
    newcompetition,
    notifications,
    rating,
    search,
    chat,
    eventHash
  }
})

