import axios from 'axios'
import { apiUrl } from '@/config'
import { getItem } from '@/helpers/persistanceStorage'

axios.defaults.baseURL = apiUrl

axios.interceptors.request.use(config => {
  const token = getItem('accessToken')
  const authorizationToken = token ? `Bearer ${token}` : ''
  config.headers.Authorization = authorizationToken
  return config
})

export default axios
