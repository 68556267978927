import compApi from '@/api/competition'

function defaultState () {
  return {
    sport: null,
    date: null,
    gender: "mixed",
    details: [],
    appearance: {},
    competitors: [],
    viewers: [],
    selectedAddonsIds: {},
    invite_hashes: []
  }
}

const state = defaultState()

const getters = {
  addonsPrice(state, getters) {
    const addonsPrice = getters.selectedAddons.reduce(function(previousValue, currentValue) {
      return previousValue + currentValue.values.find(item => item.id === state.selectedAddonsIds[currentValue.id]).price;
    }, 0);
    const venuePrice = state?.appearance?.venue?.price !== undefined ? state.appearance.venue.price : 0;
    return addonsPrice + venuePrice;
  },
  selectedAddons(state) {
    if (!(state.sport?.appearance?.addons?.variations)) {
      return [];
    }
    return state.sport.appearance.addons.variations.filter(item => state.selectedAddonsIds[item.id]!==undefined)
  },
}

const mutations = {
  SET_SPORT (state, payload) {
    state.sport = payload
  },
  SET_DATE (state, payload) {
    state.date = payload
  },
  SET_GENDER (state, payload) {
    state.gender = payload
  },
  SET_DETAILS (state, payload) {
    state.details = payload
  },
  SET_DETAIL (state, { id, value }) {
    const detail = state.details.find(detail => detail.id === id)

    detail.value = value
  },
  SET_APPEARANCE (state, payload) {
    state.appearance = payload
  },
  UPDATE_APPEARANCE (state, payload) {
    Object.assign(state.appearance, { [payload.type]: payload.value })
  },
  ADD_COMPETITOR (state, competitor) {
    state.competitors.push(competitor)
  },
  REMOVE_COMPETITOR (state, competitor) {
    state.competitors = state.competitors.filter(({ id }) => id !== competitor.id)
  },
  ADD_VIEWER (state, viewer) {
    state.viewers.push(viewer)
  },
  REMOVE_VIEWER (state, viewer) {
    state.viewers = state.viewers.filter(({ id }) => id !== viewer.id)
  },
  RESET_STORE (state) {
    Object.assign(state, defaultState())
  },
  SET_SELECTED_ADDONS(state, selectedAddons) {
    state.selectedAddonsIds = selectedAddons
  },
  SET_STATE(state, restoredState) {
    for (let key in restoredState) {
      console.log(key)
      state[key] = restoredState[key]
    }
  },
  SET_INVITES_BY_LINK(state, invites) {
    state.invite_hashes.push(invites)
  },
  REMOVE_INVITE_BY_LINK(state, inviteHash) {
    state.invite_hashes = state.invite_hashes.filter(i => i.hash !== inviteHash)
  },
  RESET_COMPETITORS_LIST(state) {
    state.competitors = [];
  }
}

const actions = {
  setSport ({ commit }, sport) {
    commit('SET_SPORT', sport)
  },
  setDate ({ commit }, date) {
    commit('SET_DATE', date)
  },
  setGender ({ commit }, gender) {
    commit('SET_GENDER', gender)
  },
  setDetails ({ commit }, details) {
    commit('SET_DETAILS', details)
  },
  setDetail ({ commit }, detail) {
    commit('SET_DETAIL', detail)
  },
  setAppearance ({ commit }, appearance) {
    commit('SET_APPEARANCE', appearance)
  },
  updateAppearance ({ commit }, data) {
    commit('UPDATE_APPEARANCE', data)
  },
  addCompetitor ({ commit }, competitor) {
    commit('ADD_COMPETITOR', competitor)
  },
  removeCompetitor ({ commit }, competitor) {
    commit('REMOVE_COMPETITOR', competitor)
  },
  addViewer ({ commit }, user) {
    commit('ADD_VIEWER', user)
  },
  removeViewer ({ commit }, user) {
    commit('REMOVE_VIEWER', user)
  },
  reset ({ commit }) {
    commit('RESET_STORE')
  },
  resetCompetitorsList({ commit }) {
    commit("RESET_COMPETITORS_LIST")
  },
  async create (_, newcompetition) {
    try {
      const response = compApi.sendSportsResult(newcompetition)
      return response
    } catch (e) {
      console.log({e})
    }
  },
  restoreState({commit}) {
    const localStorageValue = localStorage.getItem('savedCompetition');
    if (localStorageValue===null) {
      return;
    }
    try {
      const restoredState = JSON.parse(localStorageValue)
      commit('SET_STATE', restoredState.state)
    } catch (e) {
      console.error(e);
    }

  },
  saveState({state}) {
    try {
      localStorage.setItem('savedCompetition', JSON.stringify({
        state
      }))
    } catch (e) {
      console.error(e);
    }
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}

