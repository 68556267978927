import axios from '@/plugins/axios'

export function getDialogs() {
  return axios.get('/profile/chats')
}

export function deleteDialog(userID) {
  return axios.delete(`/profile/chat/${userID}`)
}

export function getMessages(userID) {
  return axios.get(`/profile/chat/${userID}`)
}

export function sendMessages(formData, userID) {
  return axios.post(`/profile/chat/${userID}`, formData)
}
