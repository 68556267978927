import axios from '@/plugins/axios'

const sendSportsResult = data => {
  return axios.post('/profile/competitions', data)
}

const getCompetition = id => {
  return axios.get(`/profile/competitions/${id}`)
}

const getCompetitions = () => {
  return axios.get('/profile/competitions')
}


export function getTournaments () {
  return axios.get('/profile/tournaments')
}

export function getEvents () {
  return axios.get('/profile/events')
}

const getKindSports = () => {
  return axios.get('profile/kind-sports')
}

const getUsers = () => {
  return axios.get('/profile/users')
}

const getUserByID = id => {
  return axios.get(`/profile/users/${id}`)
}

const getUserByIDGuest = id => {
  return axios.get(`/public/users/${id}`)
}

const getReferees = () => {
  return axios.get('/profile/users/referees')
}

const getOpponets = () => {
  return axios.get('/profile/users/opponents')
}

const getGenderOpponents = (gender) => {
  return axios.get('/profile/users/opponents', {params: {gender: gender}})
}

const getChallenges = () => {
  return axios.get('/profile/challenges')
}

const setApply = (data) => {
  return axios.post(`/profile/heats`, data)
}

const setAccept = id => {
  return axios.post(`/profile/challenges/take/${id}`)
}

const setReject = id => {
  return axios.post(`/profile/challenges/reject/${id}`)
}

export function getVerdicts () {
  return axios.get('/profile/competitions/verdicts')
}

export default {
  getKindSports,
  getUsers,
  getReferees,
  getOpponets,
  getGenderOpponents,
  sendSportsResult,
  getCompetition,
  getCompetitions,
  getTournaments,
  getEvents,
  getUserByID,
  getUserByIDGuest,
  getChallenges,
  setAccept,
  setReject,
  getVerdicts,
  setApply
}
