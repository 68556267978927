<template>
  <a-drawer
    placement="bottom"
    :closable="false"
    :visible="open"
    :height="height"
    wrapClassName="popup"
    @close="close"
  >
    <div class="popup-container" :class="classes">
      <div class="close" @click="close">
        <Icon :size="14" type="caret-down"/>
      </div>

      <div class="content">
        <slot></slot>
      </div>

      <div v-if='$slots.actions' class="actions">
        <slot name="actions"></slot>
      </div>
    </div>
  </a-drawer>
</template>

<script>
import Icon from '@/components/Icon'

export default {
  name: 'Popup',
  props: {
    open: {
      type: Boolean,
      default: false,
      required: true
    },
    height: {
      type: [Number, String],
      default: 'auto'
    },
    containerClass: {
      type: String,
      default: null
    }
  },
  components: {
    Icon
  },
  computed: {
    classes () {
      const classes = []

      if (this.containerClass) {
        classes.push(this.containerClass)
      }

      return classes
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>
